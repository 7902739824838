import React from 'react';
import { Link } from 'gatsby';
import { FaChevronLeft } from 'react-icons/fa';
import tastyGravyProducts from '../images/tastyGravyProducts.jpg';
import '../components/style.scss';

const tastyGravy = () => (
  <section className="section">
    <div className="container">
      <div className="columns">
        <Link to="/#projects">
          <button
            type="button"
            className="button is-outlined is-primary is-medium"
          >
            <FaChevronLeft />
          </button>
        </Link>
        <div className="column is-half is-size-5">
          <h1 className="title is-size-1 is-centered">
            Tasty Gravy - An E-commerce Website
          </h1>
          Built from scratch on React and Redux, this website is a full
          e-commerce experience. Users can review and filter through products,
          create an account, create a cart, and review their order history.
          Utilizing Stripe, this websiite can also accept payments, as well as
          keep track of orders and their display an orders status to the user.
          <br />
          <br />
          <a
            className="text is-size-5"
            href="https://gravytrainofficial.herokuapp.com/"
          >
            https://gravytrainofficial.herokuapp.com/
          </a>
          <br />
          <a
            className="text is-size-5"
            href="https://github.com/GravyTrainOfficial/TastyGravy"
          >
            Source Code
          </a>
          <img src={tastyGravyProducts} alt="tastyGravy" />
        </div>
      </div>
    </div>
  </section>
);

export default tastyGravy;
